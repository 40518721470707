<template>
    <v-container fluid>
      
      <v-layout wrap>
        <v-col cols="12">
          <v-row>
            <v-col cols="12" class="rowBg">
              <h4>
                <v-icon color="#BD2121" style="font-size:35px;" class="mr-4"
                  >mdi-lock-open-outline</v-icon
                >
                New Backup Codes
              </h4>
            </v-col>
          </v-row>
  
          <v-row>
            <v-col cols="12">
              <v-stepper v-model="e1" alt-labels>
                <v-stepper-header>
                  <v-stepper-step :complete="e1 > 1" step="1" color="#72BD61">
                    Backup Codes
                  </v-stepper-step>
  
                  <v-divider></v-divider>
  
                  <v-stepper-step step="2" color="#72BD61">
                    Scan 2FA Code
                  </v-stepper-step>

                  <v-divider></v-divider>
  
                  <v-stepper-step step="3" color="#72BD61">
                    Finish
                  </v-stepper-step>
                </v-stepper-header>
  
                <v-stepper-items>
                  <v-stepper-content step="1" style="border:none;">
                    <v-row wrap>
                      <v-col cols="10" offset="1">
                        <v-icon class="mr-5" color="#2776ED"
                          >mdi-alert-circle-outline</v-icon
                        >
                        Backup Codes
                      </v-col>
                    </v-row>

                    <v-row wrap>
                      <v-col cols="12" md="10" offset-md="1" class="colBg">
                        <p>
                            Backup Codes are used to access your account in the event you cannot receive two-factor authentication. For security reasons, each code can be used only once.
                        </p>
                        <p style="color:red;">
                            Copy your Backup Codes before continuing two-factor authentication setup.
                        </p>

                        <v-list>
                          <v-list-item 
                          v-for="link in userDetail.backupCodes"
                          :key="link"
                          >
                            <v-list-item-title>
                              {{link}}
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>

                        <h6 class="mt-5">
                            Treat your Backup Codes with the same level of attention as you would your password!
                        </h6>
                        <p>We recommend saving with a password manager such as <a target="_blank" href="https://www.lastpass.com/">Lastpass</a>, 
                            <a target="_blank" href="https://1password.com/">1Password</a>, or 
                            <a target="_blank" href="https://www.keepersecurity.com/">Keeper</a>.
                        </p>
                      </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="text-center" cols="12">
                          <v-btn class="buttonStyle" @click="e1 = 2">
                            Next
                            </v-btn>
                            <v-btn  class="buttonStyle3 ml-2" color="red" @click="goBack">
                            Cancel
                            </v-btn>
                            <!-- <v-btn class="buttonStyle" v-if="!userDetail.isTwoFA" text color="blue" :to="{ name: 'pharma2FA', params: { user_id: userId } }">
                            Next
                            </v-btn>
                            <v-btn  class="buttonStyle3 ml-2" v-if="!userDetail.isTwoFA" text color="red" :to="{ name: 'pharma2FABackupcodes', params: { user_id: userDetail.userId } }">
                            Cancel
                            </v-btn> -->
                        </v-col>
                    </v-row>

  
                    
                  </v-stepper-content>

                  <v-stepper-content step="2" style="border:none;">
                    <v-row wrap>
                      <v-col cols="10" offset="1">
                        <v-icon class="mr-5" color="#2776ED"
                          >mdi-alert-circle-outline</v-icon
                        >
                        Set up 2FA for your account.
                      </v-col>
                    </v-row>

                    <v-row wrap>
                      <v-col cols="12" md="10" offset-md="1" class="colBg">
                          <p>
                            Two-factor authentication, also known as 2FA or multi-factor, adds an extra layer of security to your account beyond your username and password. 
                            When you login with 2FA enabled, 
                            you will be prompted to use a security key, enter a verification code or approve the login from your mobile device, depending on which method you choose below.
                          </p>
                      </v-col>
                    </v-row>
  
                    <v-row wrap>
                      <v-col cols="12" md="10" offset-md="1">
                        <v-card
                          class="mt-1"
                          height="auto"
                          style="border:2px dotted black !important;padding:30px;"
                        >
                          <v-row>
                            <v-col cols="12" md="8" offset-md="2">
                                <h3>Scan this QR code with your app</h3>
                                <p>Scan the QR code below with the two-factor authentication app on your phone:</p>
                                <v-img
                                alt="System Logo"
                                class="shrink"
                                style="margin-right:90px !important;"
                                contain
                                :src="userDetail.qrcode"
                                transition="scale-transition"
                                width="400"
                                />
                            </v-col>
                          </v-row>
  
                          <v-row wrap>
                            <v-col cols="12" md="8" offset-md="2">
                              <h3>Enter the six-digit code from the application</h3>
                              <p>Scan the QR code below with the two-factor authentication app on your phone:</p>
                              <v-text-field
                                dense
                                outlined
                                color="#BD2121"
                                placeholder="6 digits"
                                type="text"
                                label="Enter six-digit"
                                v-model="code"
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>
  
                        
                          <v-row wrap>
                            <v-col cols="12" md="7" offset-md="4">
                              <v-btn
                                class="buttonStyle2"
                                @click.prevent="verifyCode"
                              >
                                Submit Code
                              </v-btn>

                            </v-col>
                          </v-row>
                        </v-card>
                      </v-col>
                    </v-row>
  
                    
                  </v-stepper-content>



                  <v-stepper-content step="3">
                    <v-row wrap>
                      <v-col cols="10" offset="1">
                        <v-icon class="mr-5" color="#2776ED"
                          >mdi-alert-circle-outline</v-icon
                        >
                        You have successfully turned ON Two-Factor Authentication.
                      </v-col>
                    </v-row>
  
                    
                    <v-row wrap>
                            <v-col cols="12" md="10" offset-md="1">
                              <v-btn
                                class="buttonStyle2"
                                @click.prevent="refreshPage"
                              >
                                Done
                              </v-btn>
                            </v-col>
                    </v-row>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-col>
          </v-row>
        </v-col>
      </v-layout>
    </v-container>
  </template>
  
  <script>

  export default {
    name: "2FAComponent",
  
    data: () => ({
      e1: 1,
      dialogUploadPicture: false,
      dialogEditProfile: false,
      dialogChangeEmail: false,
      emailResentStatus: false,
      preview: false,
      decodedEmail: "",
      imageLoader: false,
      newEmail:"",
     
      UpdateImage: {
        id: "",
        profileImage: "",
      },
      userDetail: {},
      successMessage: false,
      code:"",
    }),
    mounted() {
        this.setAuthenticatedUser();
    },
    methods: {
     setAuthenticatedUser() {
        this.userId = localStorage.getItem("userId");

        
        const data = {
        password:localStorage.getItem("otherfile")
       }

        this.$http
            .put(`${process.env.VUE_APP_URL}user/twofa/` + this.userId, data)
            .then((response) => {
            this.userDetail = response.data.data;
            
            })
            .catch((error) => {
                console.log(error);
                // this.errorMessage = error.response.data.data;
            });
      },
      goBack() {
        // Use router.go(-1) to go back to the previous page
        this.$router.go(-1);
      },
      verifyCode()
      {
        this.userId = localStorage.getItem("userId");
        const data = {
            code:this.code
        };
        this.$http
            .put(`${process.env.VUE_APP_URL}user/confirm/twofa/` + this.userId, data)
            .then(() => {
              this.e1 = 3;
            })
            .catch((error) => {
                console.log(error);
            });
      },
      refreshPage()
      {
        // this.$router.push({
        //   name: "pharmaUserAccountSettings",
        // });
        this.$router.go(-1);
      }


    },
  };
  </script>
  
  <style scoped>
  .rowBg {
    background-color: #f2f2f2;
    border-radius: 10px;
    padding: 10px 0px 5px 20px;
  }
  
  .colBg {
    background-color: #f2f2f2;
    border-radius: 10px;
    padding: 25px;
  }
  
  .iconStyle {
    font-size: 40px;
    color: #dd6464;
    transform: rotate(180deg);
  }
  
  .buttonStyle {
    background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
    border-radius: 10px;
    color: #ffffff !important;
    font-size: 16px;
    padding-left: 45px !important;
    padding-right: 45px !important;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .buttonStyle3 {
    background: #dd6464;
    border-radius: 10px;
    color: #ffffff !important;
    font-size: 16px;
    padding-left: 45px !important;
    padding-right: 45px !important;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  
  .buttonStyle2 {
    background: linear-gradient(180.88deg, #72bd61 33.53%, #111212 95.69%);
    border-radius: 10px;
    color: #ffffff !important;
    font-size: 14px;
    padding-left: 15px !important;
    padding-right: 15px !important;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 5px;
    margin-left: 25px;
  }
  
  li {
    font-size: 12px;
    margin-top: 5px;
  }
  
  .defaultAddressStyle {
    width: 130px;
    height: auto;
    background: rgba(17, 18, 18, 0.47);
    color: #ffffff;
    padding: 5px;
    border-top-left-radius: 5px;
  }
  
  .photoBorderStyle {
    border: 1px solid #4176c7;
    padding: 5px;
  }
  
  .centerImg {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  
  .resendEmailStyle {
    background-color: #fafafa;
    padding: 10px;
    color: black;
  }
  
  .noteStyle {
    background-color: #c7c3a6;
    padding: 15px;
    color: #000000;
  }
  </style>
  